import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h1',{staticClass:"mt-4 text-center",class:{
                    'text-h2': _vm.$vuetify.breakpoint.lgAndUp,
                    'text-h3': !_vm.$vuetify.breakpoint.lgAndUp,
                }},[_vm._v(" DStv channel List ")])])],1),_c(VRow,_vm._l((_vm.dstvPackageList),function(item){return _c(VCol,{key:item.name,attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('dstv-package',{attrs:{"item":item}})],1)}),1),_c(VRow,{staticClass:"mt-8 mb-5"},[_c('back-button',{staticClass:"mx-auto"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }